<template>
  <div class="container">
      <ul class="imgbox">
        <img class="arrow arrowleft" @click="leftArrow" src="../assets/image/ic_nav_return2.png" alt="">
        <div style="width:274px;display:flex;margin-right:6px;">
          <li class="swipimg" :ref="'imgli-'+ item.code" v-for="item in imgList" :key="item.code" @click="chackGift(item)">
            <img class="giftimg" :src="item.img_url" alt="">
            <i style="display: inline-block;height: 35px;width: 100%;overflow: hidden;">{{item.name}}</i>
          </li>
        </div>
        <img class="arrow arrowright" @click="rightArrow" src="../assets/image/ic_nav_enter.png" alt="">
      </ul>

      <!-- 型号 -->
      <div id="inputmodel" ref="inputmodel" class="modelInput">
        <span id="inputstar">*</span>
        <input ref="input" type="text" disabled placeholder="Your machine model" @focus="onfoucus" @blur="onblur" class="commenmoinput" v-model="form.camera_model">
      </div>
      <!-- 站点 -->
      <drop-station v-if="childshow" placeholder="Purchat at" @selectvalue="getStation" v-bind:defaultsite="sitedefaultid" v-bind:stationData="stationData"></drop-station>

      <selfinput placeholder="Order Number" class="orderfa" @transin="getOrder"></selfinput>
      <div class="getorder" @click="copyOrder">How to copy the order number? </div>
      <selfinput placeholder="Your Name" class="orderfa" @transin="getName"></selfinput>
      <selfinput placeholder="Contanct Email" class="orderfa" @transin="getEmail"></selfinput>

      <div ref="textbox" class="textbox">
        <span id="inputstar">*</span>
        <textarea @focus="textfoucus" @blur="textblur" placeholder="Gift mailing address" class="textarea" v-model="form.address" required></textarea>
      </div>
      <div class="getorder">* Add Review Screensho</div>
      <!-- 上传图片 -->
      <upload-img class="uploadfa" v-bind:limit="1" @imgsrc="hassrc" @imgfile="getupload"></upload-img>
      <button class="send marginsend" ref="applygift" @click="isSend">Send</button>

      <dialogue v-if="popdio" @closedio="closedio" v-bind:tiptype="tiptype" v-bind:erromsg="erromsg"></dialogue>
      <gologin @gologin="getlogin" v-if="isLogin"></gologin>

  </div>
</template>

<script type="text/ecmascript-6">
import gologin from '../components/goLogin'
import dialogue from '../components/dialogue'
import dropStation from '../components/dropselect'
import selfinput from '../components/inputself'
import uploadImg from '../components/uploadimg'
import Cryptojs from 'crypto-js'
export default {
  name: '',
  mixins: [],
  components: {
    dropStation,
    selfinput,
    uploadImg,
    dialogue,
    gologin
  },
  props: {},
  data () {
    return {
      sitedefaultid: '',
      search: {
        activity_id: '',
        language_code: '',
        page: 1,
        limit: 3,
      },
      form: {
        unique_number: '',
        camera_model: '',
        activity_id: 1,
        gift_id: '',
        review_screenshot: '',
        user_name: '',
        contact_email: '',
        order_number: '',
        site_id: '',
        address: '',
        // country_code: '',
      },
      order: '',
      name: '',
      email: '',
      address: '',
      fileList: {},
      imgList: [],
      stationData: [],
      stationcontent: {},
      modelcontent: {},
      value: '',
      total_page: 0,
      childshow: false,
      file: {},

      // 弹框
      popdio: false,
      tiptype: true,
      erromsg: '',//

      // 签名信息
      signinfo: {
        uid: '',
        api_token: '',
        email: '',
        username: '',
        appid: 'd0a4667742d0e7d7',
        secret: '8c56b4dfd0a4667742d0e7d7712ea9af',
      },

      isLogin: false,
      ifsrc: '',
      switch: false,
      getislogin: false,
      lancode: '',
      activity_id: false,
      lan_code: false,
    }
  },
  computed: {
    issendcolor(){
      const {
        unique_number,
        camera_model,
        activity_id,
        gift_id,
        user_name,
        contact_email,
        order_number,
        site_id,
        address
      } = this.form
      const ifsrc = this.ifsrc
      return {
        unique_number,
        camera_model,
        activity_id,
        gift_id,
        user_name,
        contact_email,
        order_number,
        site_id,
        address,
        ifsrc
      }
    }
  },
  watch: {
    issendcolor(e){
      if (e.gift_id && e.camera_model && e.site_id && e.order_number && e.user_name && e.contact_email && e.address && e.ifsrc) {
        this.$refs.applygift.classList.remove('marginsend')
        this.$refs.applygift.classList.add('fullmarginsend')
        this.switch = true
      } else {
        this.switch = false
        this.$refs.applygift.classList.add('marginsend')
        this.$refs.applygift.classList.remove('fullmarginsend')
      }
    }
  },
  methods: {
    closedio () {
      this.popdio = false
    },
    getSign (appid,secret,timestamp,uid,api_token) {
      var str =  appid + timestamp + uid + api_token
      var hash = Cryptojs.HmacSHA256(str, secret)
      var hashInBase64 = hash.toString()
      var jiamistr = Cryptojs.enc.Utf8.parse(hashInBase64)
      var base64 = Cryptojs.enc.Base64.stringify(jiamistr)
      return base64
    },
    textfoucus () {
      this.$refs.textbox.style.border = '1px solid #E02020'
      this.$refs.textbox.style.borderRadius = '3px'
    },
    textblur () {
      this.$refs.textbox.style.border = 'none'
    },
    GetsiteList () {
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
      this.$axios.get('/v2/invite_comments/products_to_site',{
        params: {
          activity_product: this.form.camera_model
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
         if (res.data.code == 1000) {
          this.childshow = true
          this.stationData = res.data.data
        }
      })
    },
    leftArrow () {
      if (this.search.page > 1) {
        this.search.page = this.search.page - 1
        this.GetgiftList()
      }
    },
    rightArrow () {
      if (this.search.page < this.total_page) {
        this.page = this.search.page++
        this.GetgiftList()
      }
    },
    chackGift (item) {
      this.eventTracking('00060','2033','104','','','')
        this.$refs['imgli-'+item.code].getElementsByTagName('img')[0].style.border = '3px solid #FD6700'
        if (this.imgList && this.imgList.length!==0) {
          this.imgList.forEach(ele => {
            if (ele.code !== item.code) {
              this.$refs['imgli-'+ele.code].getElementsByTagName('img')[0].style.border = 'none'
            }
          })
        }
        this.form.gift_id = item.id
    },
    // 礼物列表
    GetgiftList () {
      if (this.activity_id && this.lan_code) {
        let timestamp = Date.parse(new Date()).toString().substr(0,10)
        let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
        this.$axios.get('/v2/invite_comments/reward_list',{
          params: {
            activity_id: +this.form.activity_id,
            language_code: this.lancode,
            page: this.search.page,
            limit: this.search.limit
          },
          headers: {
            'appid': this.signinfo.appid,
            'timestamp': timestamp,
            'sign': sign,
            'language': this.lancode
          }
        }).then(res => {
          if (res.data.code == 1000) {
            this.imgList = res.data.data.data
            this.total_page = res.data.data.page_info.total_page
          }
        })
      }
    },
    copyOrder () {
      window.location.href = 'https://sellercentral.amazon.com/gp/help/external/201724070?language=en_US' + '?v=' + (new Date().getTime())
    },
    // 站点
    getStation (item) {
      this.form.site_id = item.id
    },
    getOrder (order) {
      this.form.order_number = order
    },
    getName (name) {
      this.form.user_name = name
    },
    getEmail (email) {
      this.form.contact_email = email
    },
    // 上传组件值
    getupload (file) {
      this.file = file
    },
    hassrc (item) {
      this.ifsrc = item
    },
    getlogin (item) {
      this.getislogin = item
      if (this.getislogin) {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          try {
            window.webkit.messageHandlers.nativeLogin.postMessage(null)
          } catch(error) {
            console.log(error)
          }
        }
        if (/(Android)/i.test(navigator.userAgent)) {
          window.nativeJsObject.nativeLogin()
        }
        this.isLogin = false
        return
      }
      this.isLogin = false
    },
    // 提交
    isSend () {
      if (!this.switch) {
        return
      }
      this.impleNative()
      // 0.没有登陆跳转至登录
      if (!this.signinfo.uid || !this.signinfo.api_token) {
        this.isLogin = true
        return
      }else {
        this.isLogin = false
      }
      // this.isLogin = true
      let params = {
        userid: this.signinfo.uid,
        username: this.signinfo.username,
        filesize: this.file.size,
        file_extension: this.file.name.split('.')[1],
        file_num: 1,
        upload_target: 'amazon_grade',
        file_prefix: this.form.camera_model,
      }
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,this.signinfo.uid,this.signinfo.api_token)
      // 预签名
      this.$axios.get('/v3/cloud/put_userfilepresignurl',{
        params: params,
        headers: {
          'uid': this.signinfo.uid,
          'api-token': this.signinfo.api_token, 
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          this.upapplyimg(res.data.data.url)
          return
        }
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      })
    },
    upapplyimg (item) {
      let _that = this
      let urllast = item.split('com.cn/')[1]
      let url = '/aws/' + urllast
      this.$axios.put(url, this.file,{
        headers: {
          'X-Amz-Tagging': 'username=' + _that.signinfo.username,
          'X-Amz-Server-Side-Encryption': 'AES256',
        }
      }).then(res => {
        if (res.status == 200) {
          this.confirmSend()
          return
        }
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        alert('上传' + error)
      })
    },
    confirmSend () {
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,this.signinfo.uid,this.signinfo.api_token)
      this.$axios.post('/v2/invite_comments/apply_for_gift',{
        unique_number: this.form.unique_number,
        camera_model: this.form.camera_model,
        activity_id: +this.form.activity_id,
        gift_id: this.form.gift_id,
        review_screenshot: this.form.camera_model + '_' + '01.' + this.file.name.split('.')[1],
        user_name: this.form.user_name,
        contact_email: this.form.contact_email,
        order_number: this.form.order_number,
        site_id: this.form.site_id,
        address: this.form.address,
      },{
        headers: {
          'uid': this.signinfo.uid,
          'api-token': this.signinfo.api_token,
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      })
      .then(res => {
        this.eventTracking('00062','2033','104','','','')
        if (res.data.code == 1000) {
          this.popdio = true
          this.tiptype = true
          return
        }
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        alert(error)
      })
    },
    onfoucus () {
     this.$refs.inputmodel.style.border = '1px solid #E02020'
     this.$refs.inputmodel.style.borderRadius = '3px'
    },
    onblur () {
      this.$refs.inputmodel.style.border = 'none'
    },

    getLoginMsg (item) {
      let data = JSON.parse(item)
      this.signinfo.email = data.email
      this.signinfo.uid = data.uid
      this.signinfo.api_token = data.api_token
      this.signinfo.username = data.username
    },
    getActivityMsg(item){
      let data = JSON.parse(item)
      this.form.activity_id = data.activity_id
      this.form.camera_model = data.camera_model
      this.form.unique_number = data.unique_number
      this.GetsiteList()
      this.activity_id = true
      this.GetgiftList()
    },
    getLanguage (item) {
      this.lancode = item
      this.lan_code = true
      this.GetgiftList()
    },
    impleNative () {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeGetLoginMsg.postMessage(null)
          window.webkit.messageHandlers.nativeEventTracking.postMessage(null)
          window.webkit.messageHandlers.nativeGetActivityMsg.postMessage(null)
          window.webkit.messageHandlers.nativeLanguage.postMessage(null)
        } catch(error) {
          console.log(error)
        }
        window.getLoginMsg = this.getLoginMsg
        window.getActivityMsg = this.getActivityMsg
        window.getLanguage = this.getLanguage
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        let logindata = ''
        logindata = window.nativeJsObject.nativeGetLoginMsg()
        this.getLoginMsg(logindata)
        // window.nativeJsObject.nativeEventTracking()
        let activitydata = ''
        activitydata = window.nativeJsObject.nativeGetActivityMsg()
        this.getActivityMsg(activitydata)
        let langue = ''
        langue = window.nativeJsObject.nativeLanguage()
        console.log('语言'+langue)
        this.getLanguage(langue)
      }
    },
    eventTracking(a,b,c,d,e,f) {
      // 进入埋点
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeEventTracking.nativeEventTracking(a,b,c,d,e,f)
        } catch(error) {
          console.log(error)
        }
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        window.nativeJsObject.nativeEventTracking(a,b,c,d,e,f)
      }
    }
  },
  filters: {},
  mounted () {
    // 先调用app
    this.impleNative()
    this.eventTracking('00061','2033','104','','','')
  },
  created () {
    this.sitedefaultid = this.$route.params.id
  }
}
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  padding: 0 10px;
  max-width: 345px;
  margin: 0 auto;
}
.modelInput {
  margin-top: 35px!important;
  margin-bottom: 10px!important;
}
.orderfa {
  margin-top: 10px!important;
}
.textarea {
  padding: 8px 15px;
  width: 100%;
  min-height: 91px;
  background-color: #2B323D;
  border: none;
  margin: 0 auto;
  margin-top: 10px;
  outline: none;
  font-family: Avenir;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(255,255,255,.5);
  border-radius: 3px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box
}
i{
  font-family: Avenir;
  font-size: 9px;
  font-style: italic;
  font-weight: 900;
/* //   line-height: 12px; */
  letter-spacing: 0px;
  text-align: center;
  color: rgba(255, 255, 255,50%);
  display: block;
  margin-top: 10px;
}
.getorder {
    width: 100%;
    text-align: left;
    margin: 10px auto 15px;
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #FD6700;
    cursor: pointer; /* IOS对于非可点击元素事件的不触发 */
  }
  .send {
    width: 100%;
    height: 55px;
    border: none ;
    /* //styleName: 拉丁语系/主按钮; */
    font-family: Avenir;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
  }
  .marginsend {
    background-color: #2B323D;
    margin-top: 40px;
  }
  .fullmarginsend {
    margin-top: 40px;
    background-color: #FD6700;
  }
/* //   礼物列表 */
.giftimg {
  width: 70px;
  height: 70px;
  vertical-align:bottom;
  border-radius: 8px
}
.arrow {
    width: 30px;
    height: 30px;
    margin: auto 0;
}
.imgbox {
    height: 92px;
    display: flex;
    width: 100%;
    margin: 10px auto 0;
}
.arrowleft {
    margin-right: 6px;
}
.swipimg {
    word-break: break-all;
    margin-right: 32px;
}
.swipimglast {
    margin-right: 6px;
}
.checked {
    border: 3px solid #FD6700;
}

#inputmodel {
  /* width: 345px; */
  height: 60px;
  line-height: 60px;
  background-color: rgb(132, 136, 137);
  margin: 0 auto;
  display: flex;
}
#inputstar {
  color: #E02020;
  margin-left: 15px;
}
.commenmoinput {
  display: block;
  width: 100%;
  height: 58px;
  background-color: rgb(132, 136, 137);
  border: none;
  margin: 0 auto;
  /* // margin-top: 10px; */
  outline: none;
  padding: 0 15px;
  font-family: Avenir;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(255,255,255,.5);
  border-radius: 3px;
}
.textbox {
  /* width: 345px; */
  line-height: 60px;
  background-color: #2b323d;
  margin: 10px auto 0;
  display: flex;
}
</style>
